<template>
    <div class="row">
        <div class="col-md-5">
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.marcas') }}</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                            <input type="text" class="form-control nombre_completo dato" id="nombre_completo" v-model='nombre' placeholder="Nombre">
                            
                        </div>
                        <div class="col-md-1 d-flex align-items-end">
                            <button class="btn btn-primary" @click="agregarmarca(nombre)">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-sm table-striped">
                                <tbody>
                                    <tr v-for="marca in marcas" :key="marca.id">
                                        <td width="80%" v-if="!editMode[marca.id]">{{ marca.nombre_marca }}</td>
                                        <td v-else>
                                            <input type="text" v-model="marca.nombre_marca" class="form-control" />
                                        </td>
                                        <td v-if="!editMode[marca.id]"><button class="btn btn-light btn-sm" title="Editar"  @click="toggleEditMode(marca.id)"><i class="fas fa-edit" ></i></button></td>
                                        <td v-else><button @click="cancelEditMode(marca.id)" class="btn btn-light btn-sm" title="Cancelar" ><i class="fas fa-ban" ></i></button></td>
                                        <td v-if="!editMode[marca.id]"><button  title="Eliminar" class="btn btn-light btn-sm" @click="eliminarMarca(marca.id)"><i class="fas fa-trash-alt" ></i></button></td>
                                        <td v-else><button class="btn btn-light btn-sm" title="Guardar" @click="actualizarMarca(marca.id, marca.nombre_marca)"><i class="fas fa-save"  ></i></button></td>
                                    </tr>                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default {
    data() {
        return {
            nombre: '',
            marcas: [],
            editMode: {
            },
        };
    },
    watch: {
    },
    methods: {
        async toggleEditMode(marcaId) {           
            this.editMode[marcaId] = true; // Establece el estado de edición            
        },
        async cancelEditMode(marcaId) {           
            delete this.editMode[marcaId]; // Cancela el estado de edición            
            this.cargarDatos();
        },
        async cargarDatos() {
            /* get  modulos/pwgsapi/index.php/tipos-marca */
            const api = new PwgsApi;
            const datos = await api.get('tipos-marca');
            this.marcas = datos.datos;
        },
        async eliminarMarca(id) {
            /* delete  modulos/pwgsapi/index.php/especialidades/:id/marca/:id_marca */
            const api = new PwgsApi;
            await api.delete('tipos-marca/' + id);
            this.cargarDatos();
        },
        async actualizarMarca(id, nombre_marca) {
            /* delete  modulos/pwgsapi/index.php/especialidades/:id/marca/:id_marca */
            const api = new PwgsApi;
            let body = {nombre_marca: nombre_marca};
            await api.put('tipos-marca/' + id, body);
            delete this.editMode[id];
            this.cargarDatos();
        },
        async agregarmarca(nombre_marca) {
        /* post   modulos/pwgsapi/index.php/especialidades/:id/marca/:id_marca */
            const api = new PwgsApi;
            let body = {nombre_marca: nombre_marca};
            await api.post('tipos-marca/', body);
            this.cargarDatos();
        },
    },
    mounted(){
        this.cargarDatos()
    }
}

</script>